<template>
    <section>
        <div class="row mx-0 my-3 px-3 align-items-center">
            <i class="icon-left-circle f-22 text-gr-general cr-pointer" @click="$router.go(-1)" />
            <img class="img-tendero mx-2" :src="cliente.logo_mini" alt="" />
            <p class="f-500 text-general f-18">{{ cliente.nombre }}</p>
            <i class="icon-message-text-outline text-gr-general f-22 ml-auto cr-pointer" @click="AbrirChat" />
        </div>
        <div class="row mx-0">
            <div class="col text-orange f-500">
                {{ tipo? 'Pagos del':'Créditos dados al' }}  tendero durante este año
            </div>
        </div>
        <div class="row mx-0 justify-content-center">
            <el-switch v-model="tipo" v-loading="loadingCalendario" class="text-center" style="display: block" active-color="#03D6BC" inactive-color="#ff4949" active-text="Pagos" inactive-text="Cŕeditos" @change="cambioTipo" />
        </div>
        <div class="row mx-0">
            <div class="col">
                <echart v-loading="loadingCalendario" :options="calendario" />
            </div>
        </div>
        <div class="row mx-0">
            <div class="col text-blue-light f-500">
                Balance del crédito
            </div>
        </div>
        <div class="row mx-0 my-2 px-2 align-items-center">
            <div class="col">
                <echart v-loading="loadingGraficaBalance" :options="chartBar" />
            </div>
            <div class="">
                <div class="bg-gr-general br-10 mb-3 text-center p-2 text-white" style="width:150px;">
                    <p>Deuda total</p>
                    <p>{{ separadorNumero(deuda.total) }}</p>
                </div>
                <div class="bg-gr-red br-10 mb-3 text-center p-2 text-white" style="width:150px;">
                    <p>Deuda vencida</p>
                    <p>{{ separadorNumero(deuda.vencida) }}</p>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12">
                <tabla-general v-loading="loadingTabla" :data="tableData" :columnas="dataColumnas" sin-datos="No tienes cuotas">
                    <template slot="adicionales-izquierda">
                        <el-table-column prop="id_pedido" label="Pedido" sortable width="90">
                            <template slot-scope="scope">
                                {{ scope.row.id_pedido }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="valor" label="Valor del pedido" sortable min-width="100" class-name="text-right">
                            <template slot-scope="scope">
                                {{ separadorNumero(scope.row.valor_final) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="fecha_cuota" label="Fecha del pedido" sortable width="180" class-name="text-center">
                            <template slot-scope="scope">
                                <p>{{ formatearFecha(scope.row.created_at_pedido,'DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:adicionales-derecha>
                        <el-table-column prop="valor" label="Cuota" sortable min-width="100" class-name="text-right">
                            <template slot-scope="scope">
                                {{ separadorNumero(scope.row.valor) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="fecha_cuota" label="Fecha Cuota" sortable width="180">
                            <template slot-scope="scope">
                                <p :class="scope.row.vencido?'text-danger-fecha': ''">{{ formatearFecha(scope.row.fecha,'DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column width="180">
                            <template slot-scope="scope">
                                <button type="button" class="btn text-white bg-general br-10 border-0" @click="irADeuda(scope.row)">
                                    Registrar pago
                                </button>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <modal-anular-credito ref="modalAnular" />
        <modalRegistrarPagos ref="RegistrarPago" @registro="registro_nuevo_pago" @seanulo="se_anulo_credito" />
        <modalChat ref="modalChat" />
    </section>
</template>

<script>
import Creditos from '~/services/almacen/creditos'

import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
import moment from 'moment'

export default {
    components:{
        modalAnularCredito: () => import('~/pages/almacen/creditos/partials/modalAnularCredito'),
        modalRegistrarPagos: () => import('~/pages/almacen/creditos/partials/modalRegistrarPago'),

    },
    data(){
        return {
            loadingTabla: false,
            loadingGraficaBalance: false,
            loadingCalendario: false,
            tipo: false,
            id_user: parseInt(this.$route.params.id_user),
            cliente:{
                nombre: '',
                logo_mini: null,
                id_user: null
            },
            deuda:{
                total: 0,
                vencida: 0
            },
            dataColumnas: [
                'id_pedido_admin','valor_final','valor_pendiente','fecha','created_at_pedido'
            ],
            tableData: [
                // { tendero: 'OXXO', deuda: '$ 534.000', ultimo_pago: '01 Jul. 2018', ultimo_pedido: '01 Jul. 2018', proximo_pago: '05 Jul. 2008' },
            ],
            cuotas_anual: [],
            chartBar: {
                tooltip: {
                    trigger: 'axis',
                    formatter: params => {
                        const valor = this.separadorNumero(params[0].data)
                        return  params[0].axisValue +' - '+valor


                    },
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: [],
                    type: 'line',
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: '#FF9D32' },
                                {offset: 1, color: '#FF2525' },
                            ]
                        )
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: '#FF9D32' },
                                {offset: 1, color: '#FF2525' },
                            ]
                        )
                    },
                }]
            },
            calendario: {
                title: {
                    text: [],
                    left: 'center',
                    textStyle: {
                        color : '#1B1464',
                        fontWeight: 'bold'
                    }
                },
                tooltip: {
                    position: 'top'
                },
                visualMap: {
                    min: 0,
                    max: 1000000,
                    calculable: true,
                    orient: 'horizontal',
                    left: 'center',
                    top: 'bottom',
                    inRange: {
                        color: ['#FF9D32','#FF2525']
                    },
                },
                calendar: [{
                    range: [
                        moment().startOf('month').subtract(9, 'months').format('YYYY-MM-DD'),
                        moment().startOf('month').add(2, 'months').endOf('month').format('YYYY-MM-DD')],//['2020-01-01', '2021-01-01'], // necesita fechas por defecto
                    // range: ['2020-03-01', '2021-02-28'], // necesita fechas por defecto
                    yearLabel: {
                        show: false
                    },
                    monthLabel: {nameMap: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']},
                    dayLabel: {nameMap: ['D', 'L', 'M', 'M', 'J', 'V', 'S'], margin: 13},
                    cellSize: ['auto', 20]
                }],

                series: [{
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    calendarIndex: 0,
                    data: [],
                    tooltip: {
                        formatter: param => {
                            const data = param.data
                            return [
                                `<span style="color:${param.color};"></span> ${moment(data.value[0]).format('DD MMM YYYY')} <br/> ${this.separadorNumero(data.value[1])}<br/> `
                            ].join('')
                        }
                    }
                }]
            },
        }
    },
    mounted(){
        this.listarTabla()
        this.graficaBalanceCredito()
        this.graficaCalendario()
    },
    methods: {
        anular(){
            this.$refs.modalAnular.toggle()
        },
        AbrirChat(){
            this.$refs.modalChat.toggle(this.cliente.id_user, 6, false, 1) // 6 = tipo credito pedido -admin // utilizo el 1 solo para listar info cliente modal
        },
        registro_nuevo_pago(newPago){
            this.pagos_anual.push(newPago)
            if(this.tipo) this.cambioTipo() // para que recargue si esta en el calendario de pagos
            this.listarTabla()
        },
        se_anulo_credito(){
            this.listarTabla()
        },
        AbrirResgistrarPago(row){
            this.$refs.RegistrarPago.toggle(row, 2); // 2 desde tendero(Módulo creditos)
        },
        irADeuda({ id_pedido }){
            const params = {
                id_user: this.id_user,
                id_pedido
            }
            this.$router.push({ name: 'tendero-vip.creditos.cliente.ver.deuda.ver', params })
        },
        cambioTipo(){
            if(this.tipo){
                this.getVirtulData3(this.pagos_anual)
            }else{
                this.getVirtulData3(this.cuotas_anual)
            }

        },
        async listarTabla(){
            try {
                this.loadingTabla = true
                const {data} = await Creditos.verCreditoCliente(this.id_user)
                this.loadingTabla = false
                this.cliente = data.cliente
                this.tableData = data.cuotas
            } catch (e){
                this.loadingTabla = false
                this.error_catch(e)
            }
        },
        async graficaBalanceCredito(){
            try {
                this.loadingGraficaBalance = true
                const {data} = await Creditos.graficaBalanceCreditoCliente(this.id_user)
                this.loadingGraficaBalance = false
                const EJE_X = data.grafica.map(e => e.dia)
                const EJE_y = data.grafica.map(e => e.value)
                this.chartBar.series[0].data = EJE_y
                this.chartBar.xAxis.data = EJE_X
                this.deuda = data.deuda

            } catch (e){
                this.loadingGraficaBalance = false
                this.error_catch(e)
            }
        },
        async graficaCalendario(){
            try {
                this.loadingCalendario = true
                const {data} = await Creditos.graficaCalendarioCreditoCliente(this.id_user)
                this.loadingCalendario = false
                this.cuotas_anual = data.cuotas
                this.pagos_anual = data.pagos
                this.getVirtulData3(this.cuotas_anual)

            } catch (e){
                this.loadingCalendario = false
                this.error_catch(e)
            }
        },
        getVirtulData3(info){

            this.loadingGraficaBalance= true
            this.calendario.visualMap.inRange.color = this.tipo ? ['#ededed','#03D6BC']:['#FF9D32','#FF2525']
            const registros = info
            let hace_9_meses = moment().startOf('month').subtract(9, 'months').format('YYYY-MM-DD')
            let en_2_meses = moment().startOf('month').add(2, 'months').endOf('month').format('YYYY-MM-DD')
            var date = +echarts.number.parseDate(hace_9_meses);
            var end = +echarts.number.parseDate(en_2_meses);
            var dayTime = 3600 * 24 * 1000;
            var array = [];
            var cant = 0
            var max_valor = 0
            for (var time = date; time <= end; time += dayTime){
                cant = cant +1
                let acumulados = null
                registros.forEach(info => {
                    var fecha_dinamica = this.tipo ? moment(info.created_at).format('DD/MM/YYYY'): moment(info.fecha).format('DD/MM/YYYY')
                    if (fecha_dinamica=== echarts.format.formatTime('dd/MM/yyyy', time)){
                        acumulados = acumulados + parseFloat(info.valor)
                    }
                })
                if(acumulados > max_valor) max_valor = acumulados
                array.push(
                    {
                        value: [echarts.format.formatTime('yyyy-MM-dd', time), acumulados],
                        titulo: 'letrero'
                    })
            }
            this.calendario.visualMap.max = max_valor
            this.calendario.series[0].data = array
            this.loadingGraficaBalance= false
        }
    }
}
</script>

<style lang="css" scoped>
.img-tendero{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
}
.text-danger-fecha{
    color: #FF0000;
}
</style>
